<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="col-md-6">
                <label class="required tw-text-xs">{{
                    $t('uomCategory.nameEn')
                }}</label>
                <input
                    v-model="model.uom_cat_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('uom_cat_name_en')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('uom_cat_name_en')"
                >
                    {{ errors.first('uom_cat_name_en') }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="tw-text-xs">{{ $t('uomCategory.nameKh') }}</label>
                <input
                    v-model="model.uom_cat_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('uom_cat_name_kh')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('uom_cat_name_kh')"
                >
                    {{ errors.first('uom_cat_name_kh') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col-md-12 overflow-auto"
                style="min-height: 200px; max-height: 300px"
            >
                <table class="table ui-table">
                    <thead>
                        <tr>
                            <th class="tw-whitespace-nowrap">
                                {{ $t('uomCategory.nameKh') }}
                            </th>
                            <th class="tw-whitespace-nowrap">
                                {{ $t('uomCategory.nameEn') }}
                            </th>
                            <th class="tw-whitespace-nowrap" style="width: 20%">
                                {{ $t('uomCategory.stockOutQty') }}
                            </th>
                            <th class="tw-whitespace-nowrap" style="width: 7%">
                                {{ $t('uomCategory.default') }}
                            </th>
                            <th style="width: 3%">
                                <a
                                    href="#"
                                    class="text-primary"
                                    @click.prevent="addNewItem"
                                >
                                    <i class="fas fa-plus"></i>
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in model.uom_list.filter(
                                a => a.is_deleted == false
                            )"
                            :key="index"
                        >
                            <td>
                                <input
                                    type="text"
                                    v-model.trim="item.uom_name_kh"
                                    class="form-control kh-font only-border-bottom"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'uom_list.' + index + '.uom_name_kh'
                                        )
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'uom_list.' + index + '.uom_name_kh'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            'uom_list.' + index + '.uom_name_kh'
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    v-model.trim="item.uom_name_en"
                                    class="form-control only-border-bottom"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'uom_list.' + index + '.uom_name_en'
                                        )
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'uom_list.' + index + '.uom_name_en'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            'uom_list.' + index + '.uom_name_en'
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    v-model.number="item.stockout_quantity"
                                    class="form-control only-border-bottom"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'uom_list.' +
                                                index +
                                                '.stockout_quantity'
                                        )
                                    }"
                                    placeholder="Enter quantity"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'uom_list.' +
                                                index +
                                                '.stockout_quantity'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            'uom_list.' +
                                                index +
                                                '.stockout_quantity'
                                        )
                                    }}
                                </div>
                            </td>
                            <td class="tw-py-1">
                                <Checkbox
                                    v-model="item.is_default"
                                    @on-change="
                                        setDefault(item.is_default, index)
                                    "
                                >
                                </Checkbox>
                            </td>
                            <td>
                                <a
                                    href="#"
                                    class="text-danger"
                                    @click.prevent="removeItem(item)"
                                >
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-mt-3 tw-text-right">
                <ts-button
                    class="tw-mr-2"
                    @click.prevent="() => $emit('cancel')"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    @click.prevent="onSave"
                    :waiting="waiting"
                    >{{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                    >{{ $t('update') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import { isEmpty, clone } from 'lodash'

export default {
    name: 'uomForm',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                uom_cat_id: null,
                uom_cat_name_kh: null,
                uom_cat_name_en: null,
                uom_list: []
            },
            newItem: {
                uom_id: 0,
                uom_name_kh: null,
                uom_name_en: null,
                stockout_quantity: 1,
                is_default: false,
                is_deleted: false
            }
        }
    },
    computed: {
        ...mapState('product/uomCategory', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        addNewItem () {
            this.model.uom_list.push(clone(this.newItem))
        },
        removeItem (item) {
            if (item.uom_id != 0 && this.isUpdate) {
                item.is_deleted = true
            } else {
                this.model.uom_list.splice(this.model.uom_list.indexOf(item), 1)
            }
        },
        setDefault (is_default, index_key) {
            if (is_default) {
                this.model.uom_list
                    .filter(
                        (el, index) =>
                            el.is_default == true && index !== index_key
                    )
                    .forEach(el => (el.is_default = false))
            }
        },
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('product/uomCategory/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('product/uomCategory/update', {
                    id: this.model.uom_cat_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.model.uom_cat_id = 0
            this.model.uom_cat_name_kh = null
            this.model.uom_cat_name_en = null
            this.model.uom_list = []
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.uom_cat_id = this.edit_data.uom_cat_id
                this.model.uom_cat_name_kh = this.edit_data.uom_cat_name_kh
                this.model.uom_cat_name_en = this.edit_data.uom_cat_name_en
                this.model.uom_list = clone(this.edit_data.unit_of_measures)
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT CATEGORY',
                desc: not.text
            })
        }
    }
}
</script>
