var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-space-y-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('uomCategory.nameEn')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.uom_cat_name_en),expression:"model.uom_cat_name_en"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.errors.has('uom_cat_name_en')
                },attrs:{"type":"text"},domProps:{"value":(_vm.model.uom_cat_name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "uom_cat_name_en", $event.target.value)}}}),(_vm.errors.has('uom_cat_name_en'))?_c('div',{staticClass:"invalid-feedback tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first('uom_cat_name_en'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"tw-text-xs"},[_vm._v(_vm._s(_vm.$t('uomCategory.nameKh')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.uom_cat_name_kh),expression:"model.uom_cat_name_kh"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.errors.has('uom_cat_name_kh')
                },attrs:{"type":"text"},domProps:{"value":(_vm.model.uom_cat_name_kh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "uom_cat_name_kh", $event.target.value)}}}),(_vm.errors.has('uom_cat_name_kh'))?_c('div',{staticClass:"invalid-feedback tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first('uom_cat_name_kh'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 overflow-auto",staticStyle:{"min-height":"200px","max-height":"300px"}},[_c('table',{staticClass:"table ui-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('uomCategory.nameKh'))+" ")]),_c('th',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('uomCategory.nameEn'))+" ")]),_c('th',{staticClass:"tw-whitespace-nowrap",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t('uomCategory.stockOutQty'))+" ")]),_c('th',{staticClass:"tw-whitespace-nowrap",staticStyle:{"width":"7%"}},[_vm._v(" "+_vm._s(_vm.$t('uomCategory.default'))+" ")]),_c('th',{staticStyle:{"width":"3%"}},[_c('a',{staticClass:"text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addNewItem.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-plus"})])])])]),_c('tbody',_vm._l((_vm.model.uom_list.filter(
                            function (a) { return a.is_deleted == false; }
                        )),function(item,index){return _c('tr',{key:index},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.uom_name_kh),expression:"item.uom_name_kh",modifiers:{"trim":true}}],staticClass:"form-control kh-font only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'uom_list.' + index + '.uom_name_kh'
                                    )
                                },attrs:{"type":"text"},domProps:{"value":(item.uom_name_kh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "uom_name_kh", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                    _vm.errors.has(
                                        'uom_list.' + index + '.uom_name_kh'
                                    )
                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( 'uom_list.' + index + '.uom_name_kh' ))+" ")]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.uom_name_en),expression:"item.uom_name_en",modifiers:{"trim":true}}],staticClass:"form-control only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'uom_list.' + index + '.uom_name_en'
                                    )
                                },attrs:{"type":"text"},domProps:{"value":(item.uom_name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "uom_name_en", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                    _vm.errors.has(
                                        'uom_list.' + index + '.uom_name_en'
                                    )
                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( 'uom_list.' + index + '.uom_name_en' ))+" ")]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(item.stockout_quantity),expression:"item.stockout_quantity",modifiers:{"number":true}}],staticClass:"form-control only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'uom_list.' +
                                            index +
                                            '.stockout_quantity'
                                    )
                                },attrs:{"type":"number","placeholder":"Enter quantity"},domProps:{"value":(item.stockout_quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "stockout_quantity", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                    _vm.errors.has(
                                        'uom_list.' +
                                            index +
                                            '.stockout_quantity'
                                    )
                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( 'uom_list.' + index + '.stockout_quantity' ))+" ")]):_vm._e()]),_c('td',{staticClass:"tw-py-1"},[_c('Checkbox',{on:{"on-change":function($event){return _vm.setDefault(item.is_default, index)}},model:{value:(item.is_default),callback:function ($$v) {_vm.$set(item, "is_default", $$v)},expression:"item.is_default"}})],1),_c('td',[_c('a',{staticClass:"text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeItem(item)}}},[_c('i',{staticClass:"fa fa-trash"})])])])}),0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-mt-3 tw-text-right"},[_c('ts-button',{staticClass:"tw-mr-2",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('update')))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }